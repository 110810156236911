if( $('#zoho-form').length ){

	$('#zoho-form').submit(function(e){
		e.preventDefault();

		let sendForm = true;
		let form = $('#zoho-form');
		let firstName = form.find('input[name="first-name"]').val();
		let lastName = form.find('input[name="last-name"]').val();
		let emailAddress = form.find('input[name="email-address"]').val();
		let phoneNumber = form.find('input[name="phone-number"]').val();
		let company = form.find('input[name="company"]').val();
		let sector = form.find('select[name="sector"]').val();
		let enquiryType = form.find('select[name="enquiry-type"]').val();
		let message = form.find('textarea[name="message"]').val();

		if( isEmpty( firstName ) ){

			Swal.fire({
				title: "Alert",
				text: "Please enter your first name",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( isEmpty( lastName ) ){

			Swal.fire({
				title: "Alert",
				text: "Please enter your last name",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( isEmpty( emailAddress ) || !IsEmail( emailAddress ) ){

			Swal.fire({
				title: "Alert",
				text: "Please enter a valid email address",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( isEmpty( company ) ){

			Swal.fire({
				title: "Alert",
				text: "Please enter your company name",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( isEmpty( enquiryType ) ){

			Swal.fire({
				title: "Alert",
				text: "Please select your enquiry type",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( isEmpty( message ) ){

			Swal.fire({
				title: "Alert",
				text: "Please enter a message",
				icon: "info",
				confirmButtonColor:'#28788f',
			});
			sendForm = false;
			return;
		}

		if( sendForm == true ){

			$('#processing').fadeIn();

			grecaptcha.ready(function() {
	        	grecaptcha.execute('6Le4zcUqAAAAAG8RYaB8rWCJ3wprE7kGh2bYzWsO', {action: 'submit'}).then(function(token){
	            	
	            	$.ajax({
	            		type:"POST",
	            		url:"/wp-json/titanpress/v1/recaptcha-verify",
	            		data:{
	            			token:token
	            		},
	            		headers:{
							'x-wwug-header': 'incorporatewear'
						},
	            		success: function( response ){

	            			let captcha = JSON.parse( response );

	            			if( captcha.success === true ){

	            				if( captcha.score >= 0.5 ){

	            					let formData = {
	            						firstName:firstName,
										lastName:lastName,
										emailAddress:emailAddress,
										phoneNumber:phoneNumber,
										company:company,
										sector:sector,
										enquiryType:enquiryType,
										message:message
	            					}

	            					sendFormToZoho( formData );
	            				}
	            				else{

	            					Swal.fire({
										title: "Error",
										text: "Sorry Google RECAPTCHA has scored you low and blocked your submission. Please call us.",
										icon: "error",
										confirmButtonColor:'#28788f',
									});

									$('#processing').fadeOut();
	            				}
	            			}
	            			else{

	            				Swal.fire({
									title: "Error",
									text: "Sorry and error has occured verifying you with Google RECAPTCHA, please call us.",
									icon: "error",
									confirmButtonColor:'#28788f',
								});

								$('#processing').fadeOut();
	            			}
	            		},
	            		error: function(){

	            			Swal.fire({
								title: "Error",
								text: "Sorry and error has occured trying to verify you with Google RECAPTCHA, please call us.",
								icon: "error",
								confirmButtonColor:'#28788f',
							});

							$('#processing').fadeOut();
	            		}
	            	});
	        	});
	        });
	    }
	});
}

function sendFormToZoho( data ){

	$.ajax({
		type:"post",
		url:"/wp-json/titanpress/v1/zoho-contact-form",
		data:data,
		headers:{
			'x-wwug-header': 'incorporatewear'
		},
		success: function( response ){

			if( response == "success" ){

				Swal.fire({
					title: "Thank You!",
					text: "We have successfully received your enquiry and will contact you shortly.",
					icon: "success",
					confirmButtonColor:'#28788f',
				});
			}
			else{

				Swal.fire({
					title: "Error",
					text: "Sorry and error has occured sending your enquiry, please call us.",
					icon: "error",
					confirmButtonColor:'#28788f',
				});
			}

			$('#processing').fadeOut();
		},
		error: function( response ){

			Swal.fire({
				title: "Error",
				text: "Sorry and error has occured, please call us.",
				icon: "error",
				confirmButtonColor:'#28788f',
			});

			$('#processing').fadeOut();
		}
	});
}

function isEmpty(str){
    return (!str || 0 === str.length || str.length === 0 || !str.trim());
}

function IsEmail(email){
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}