/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";
import $ from "jquery";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import MicroModal from "micromodal"; // es6 module
import Animations from "./modules/Animations";
import Maps from "./modules/Maps";
import PageNavigation from "./modules/PageNavigation";
import NavBarNavigation from "./modules/NavBarNavigation"; // todo: Merge into PageNavigation Class
import Accordion from "./modules/Accordion";
import AccordionSlider from "./modules/AccordionSlider";
import AccreditationsSwiper from "./modules/AccreditationsSwiper";
import CopyMediaSlider from "./modules/CopyMediaSlider";
import CardsSlider from "./modules/CardsSlider";
import ImageGallery from "./modules/ImageGallery";
import ImagesSlider from "./modules/ImagesSlider";
import MediaFeaturesSlider from "./modules/MediaFeaturesSlider";
import PeopleSlider from "./modules/PeopleSlider";
import ProductSlider from "./modules/ProductSlider";
import PostsSlider from "./modules/PostsSlider";
import TimelineSlider from "./modules/TimelineSlider";
import VerticalSlider from "./modules/VerticalSlider";
import LoadMoreNews from "./modules/LoadMore/LoadMoreNews";
import HomeHeaderSwiper from "./modules/HomeHeaderSwiper";
import NewsDropdown from "./modules/dropdowns/NewsDropdown";
import VideoHeader from "./modules/VideoHeader";
import LoadMoreCaseStudies from "./modules/LoadMore/LoadMoreCaseStudies";
import CaseStudiesSlider from "./modules/CaseStudiesSlider";
import CaseStudyCard from "./modules/components/CaseStudyCard";
import "./site.js";
// Prefer creating new modules when appropriate, rather than adding functions here
// If you need something to be globally accessible, you can assign an imported module to this namespace
// i.e. import Contact from './contact';
// window.namespace.Contact = Contact;
const bootstrap = {
    init() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        this.helpers.initSvg();
        MicroModal.init({
            onShow: (modal) => {
                if ((modal instanceof HTMLElement)) {
                    window.document.documentElement.classList.toggle("modal-is-open", true);
                }
                // console.log(window.document.documentElement);
                // console.info(`${modal.id} is shown`);
            },
            onClose: (modal) => {
                if ((modal instanceof HTMLElement)) {
                    window.document.documentElement.classList.toggle("modal-is-open", false);
                }
                // console.info(`${modal.id} is hidden`);
            },
            debugMode: false,
        });
        Animations.init();
        Maps.init();
        /** Components */
        ((_a = document.querySelectorAll("#page-navigation")) !== null && _a !== void 0 ? _a : [])
            .forEach((el) => new PageNavigation(el));
        ((_b = document.querySelectorAll("#page-navigation")) !== null && _b !== void 0 ? _b : [])
            .forEach((el) => new NavBarNavigation(el));
        /** Blocks */
        ((_c = document.querySelectorAll(".accordion")) !== null && _c !== void 0 ? _c : [])
            .forEach((el) => new Accordion(el, "click"));
        ((_d = document.querySelectorAll(".section--accordion")) !== null && _d !== void 0 ? _d : [])
            .forEach((el) => new AccordionSlider(el));
        ((_e = document.querySelectorAll(".swiper.swiper-accreditations-slider")) !== null && _e !== void 0 ? _e : [])
            .forEach((el) => new AccreditationsSwiper(el));
        ((_f = document.querySelectorAll(".swiper.swiper-cards-slider")) !== null && _f !== void 0 ? _f : [])
            .forEach((el) => new CardsSlider(el));
        ((_g = document.querySelectorAll(".section--copy-media-slider")) !== null && _g !== void 0 ? _g : [])
            .forEach((el) => new CopyMediaSlider(el));
        ((_h = document.querySelectorAll(".section--case-studies-slider")) !== null && _h !== void 0 ? _h : [])
            .forEach((el) => new CaseStudiesSlider(el));
        ((_j = document.querySelectorAll(".section--image-gallery")) !== null && _j !== void 0 ? _j : [])
            .forEach((el) => new ImageGallery(el));
        ((_k = document.querySelectorAll(".swiper.swiper-images-slider")) !== null && _k !== void 0 ? _k : [])
            .forEach((el) => new ImagesSlider(el));
        ((_l = document.querySelectorAll(".section--media-features-a .swiper.swiper-features")) !== null && _l !== void 0 ? _l : [])
            .forEach((el) => new MediaFeaturesSlider(el));
        ((_m = document.querySelectorAll(".section--people-slider")) !== null && _m !== void 0 ? _m : [])
            .forEach((el) => new PeopleSlider(el));
        ((_o = document.querySelectorAll(".section--products-slider")) !== null && _o !== void 0 ? _o : [])
            .forEach((el) => new ProductSlider(el));
        ((_p = document.querySelectorAll(".swiper.swiper-posts-slider")) !== null && _p !== void 0 ? _p : [])
            .forEach((el) => new PostsSlider(el));
        ((_q = document.querySelectorAll(".swiper.swiper-timeline-slider")) !== null && _q !== void 0 ? _q : [])
            .forEach((el) => new TimelineSlider(el));
        ((_r = document.querySelectorAll(".swiper.swiper-vertical-slider")) !== null && _r !== void 0 ? _r : [])
            .forEach((el) => new VerticalSlider(el));
        ((_s = document.querySelectorAll(".swiper.swiper-header-text")) !== null && _s !== void 0 ? _s : [])
            .forEach((el) => new HomeHeaderSwiper(el));
        ((_t = document.querySelectorAll(".header .header__video")) !== null && _t !== void 0 ? _t : [])
            .forEach((el) => new VideoHeader(el));
        ((_u = document.querySelectorAll(".post-case-study")) !== null && _u !== void 0 ? _u : [])
            .forEach(el => new CaseStudyCard(el));
        // Fetching taxonomy from data attribute
        const newsCardGrid = document.querySelector("#news-grid");
        const selectedNewsTaxonomies = [];
        if (newsCardGrid) {
            const newsDropdown = new NewsDropdown(".tax-nav .taxonomy");
            newsDropdown.init();
            if (newsCardGrid.dataset && newsCardGrid.dataset.category) {
                selectedNewsTaxonomies.push({
                    taxonomy: "categories",
                    terms: [newsCardGrid.dataset.category],
                });
            }
        }
        const loadMoreNews = document.querySelector("#load-more-news");
        if (loadMoreNews) {
            // Load more news setup
            const loadMoreNews = new LoadMoreNews("#load-more-news", "#load-more-before", selectedNewsTaxonomies);
            loadMoreNews.init();
        }
        const loadMoreCaseStudies = document.querySelector("#load-more-case-studies");
        if (loadMoreCaseStudies) {
            // Load more case studies setup
            const loadMoreCaseStudies = new LoadMoreCaseStudies("#load-more-case-studies", "#load-more-before");
            loadMoreCaseStudies.init();
        }
        // Allow user to interrupt auto-scrolling
        $(document).bind("scroll mousedown wheel keyup", (e) => {
            if ((e.which || e.type === "mousedown" || e.type === "mousewheel") && document.scrollingElement) {
                $(document.scrollingElement).stop();
            }
        });
    },
    initMaps() {
        if (document.readyState === "complete") {
            // The page is fully loaded
            Maps.initMaps();
        }
        else {
            document.onreadystatechange = () => {
                if (document.readyState === "complete") {
                    // document ready
                    Maps.initMaps();
                }
            };
        }
    },
    helpers: {
        requireAll(r) {
            r.keys().forEach(r);
        },
        initSvg() {
            svgxhr({
                filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
            });
        },
    },
};
// @ts-ignore
window.initMaps = bootstrap.initMaps; // Callback to initialise Google Maps
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context("../images/icons/", true, /\.svg$/));
